/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, memo, useEffect, useMemo, useState} from 'react'
import {FormattedNumber, IntlProvider, useIntl} from 'react-intl'

import {BarChart} from '@/_metronic/partials/content/chart/BarChart'
import {QueryRequestProvider as RatingQueryRequestProvider} from '@/app/modules/rating-by-therapist/core/QueryRequestProvider'
import {QueryResponseProvider as RatingQueryResponseProvider} from '@/app/modules/rating-by-therapist/core/QueryResponseProvider'
import {
  QueryRequestProvider,
  useQueryRequest,
} from '@/app/modules/report-trend-analysis/core/QueryRequestProvider'
import {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
} from '@/app/modules/report-trend-analysis/core/QueryResponseProvider'
import {PageTitle} from '../../../../_metronic/layout/core'
import {TopArea} from './components/TopArea'
import {TopCity} from './components/TopCity'
import {TopEmployees} from './components/TopEmployee'
import {TopOutlet} from './components/TopOutlet'
import {TopQueryRequestProvider} from './core/QueryRequestTopProvider'
import {AreaQueryResponseProvider, useAreaQueryResponseData} from './core/QueryResponseAreaProvider'
import {CityQueryResponseProvider, useCityQueryResponseData} from './core/QueryResponseCityProvider'
import {
  OutletQueryResponseProvider,
  useOutletQueryResponseData,
} from './core/QueryResponseOutletProvider'
import {useAuth} from '@/app/modules/auth'
import {RevenueBar} from './components/RevenueBar'
import {useGetSummaryTrend} from './core/_query'
import {TreatmentTable} from '../../book-order/components/TreatmentTable'
import {TreatmentBar} from './components/TreatmentBar'
import {Modal} from 'react-bootstrap'
import {getMasterDataDropdown} from '@/app/core/master-data/_requests'
import {BaseRequest} from '@/_metronic/helpers/crud-helper/base-request'
import {FilterMasterData, MasterData} from '@/app/core/master-data/_models'
import {API_MESSAGE, RESP} from '@/_metronic/helpers/network/network-consts'
import {Button} from 'react-bootstrap'
import {toAbsoluteUrl} from '@/_metronic/helpers'

const FRONT_OFFICE = '72b0ad30-d847-47bc-af21-80f0eddca117'
const RECEIPTIONIST = '888b34c8-32f9-4554-ac98-d9696350e457'
const CASHIER = '5e10a0fb-22f7-4523-b2e1-5a9532fd741b'
const OWNER = 'd5261a49-ba33-4c44-b7c2-e7da57191ea5'

const MemoizeRevenueBar = memo(RevenueBar)
const MemoizeTreatmentBar = memo(TreatmentBar)

const DashboardPage: FC = () => {
  const [showModalSubBrand, setShowModalSubBrand] = useState<boolean>(false)
  const [subBrands, setSubBrands] = useState<MasterData[]>([])

  const {updateState} = useQueryRequest()
  const {currentUser} = useAuth()
  const data = useQueryResponseData()
  const response = useQueryResponse()
  const isLoading = useQueryResponseLoading()
  const dataCity = useCityQueryResponseData()
  const dataArea = useAreaQueryResponseData()
  const currentDate = new Date()
  const arrayMonth = [
    (currentDate.getMonth() - 4).toString(),
    (currentDate.getMonth() - 3).toString(),
    (currentDate.getMonth() - 2).toString(),
    (currentDate.getMonth() - 1).toString(),
    currentDate.getMonth().toString(),
    (currentDate.getMonth() + 1).toString(),
  ]

  console.log({currentUser})

  useEffect(() => {
    updateState({
      month: [],
    })
  }, [])

  useEffect(() => {
    if (currentUser?.detail_data?.job?.job_id === OWNER) {
      const fetchMasterDataDropdown = async () => {
        try {
          const body = {
            filter: {
              set_parent_id: false,
              parent_id: '',
              set_category: true,
              category: 'Sub Brand',
              set_value: false,
              value: '',
            },
            limit: 100,
            page: 1,
            order: 'name',
            sort: 'ASC',
          } as BaseRequest<FilterMasterData>

          const res = await getMasterDataDropdown(body)

          if (res?.response?.code === RESP.SUCCESS) {
            setSubBrands(res?.response?.data || [])
          }
        } catch (error) {
          console.log({error})
        }
      }
      setShowModalSubBrand(true)
      fetchMasterDataDropdown()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  const handleSubBrandClick = (subBrandId?: string) => {
    console.log({subBrandId})
    setShowModalSubBrand(false)
    updateState({
      set_sub_brand_id: Boolean(subBrandId),
      sub_brand_id: subBrandId ? [subBrandId] : [],
    })
  }

  const isOwner = useMemo(() => {
    return currentUser?.detail_data?.job?.job_id === OWNER
  }, [currentUser])

  return (
    <>
      <Modal
        show={showModalSubBrand}
        onHide={() => setShowModalSubBrand(false)}
        className='min-w-750px select-dashboard'
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <h5>Select Your Dashboard</h5>
          </div>
          <div className='modal-body'>
            <div className='d-flex align-items-center gap-3'>
              <Button variant='outline-dark' onClick={() => handleSubBrandClick()}>
                All
              </Button>
              {subBrands.map((subbrand) => (
                <Button
                  variant='outline-dark'
                  onClick={() => handleSubBrandClick(subbrand.guid || '')}
                >
                  <img
                    src={toAbsoluteUrl(`/media/logos/${subbrand.guid}.png`)}
                    alt={subbrand.value}
                    className='w-100'
                  />
                </Button>
              ))}
            </div>
          </div>
        </div>
      </Modal>
      {[FRONT_OFFICE, RECEIPTIONIST, CASHIER].includes(
        currentUser?.detail_data?.job?.job_id as string
      ) ? (
        <div className='g-5 w-100 h-100 text-center fs-1 text-primary fw-bold'>
          Welcome Back, {currentUser?.detail_data?.fullname}
        </div>
      ) : (
        <>
          {/* begin::Row */}
          <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            {isOwner && (
              <div className='col-12 d-flex justify-content-end'>
                <Button type='button' onClick={() => setShowModalSubBrand(true)}>
                  Filter
                </Button>
              </div>
            )}
            {/* begin::Col */}
            <div className='col-md-6 col-lg-6 col-xl-6 mb-md-5 mb-xl-3'>
              <MemoizeRevenueBar />
            </div>
            {/* end::Col */}

            {/* begin::Col */}
            <div className='col-md-6 col-lg-6 col-xl-6 mb-md-5 mb-xl-3'>
              <MemoizeTreatmentBar />
            </div>
            {/* end::Col */}

            {/* begin::Col */}
            <div className='col-xl-12'>
              <TopEmployees className='flex-fill col-xl-12' />
            </div>
            <div className='d-flex flex-row gap-4'>
              <TopOutlet className='flex-fill col-xl-4' />
              <TopCity className='flex-fill col-xl-4' />
              <TopArea className='flex-fill col-xl-4' />
            </div>
            {/* end::Col */}

            {/* begin::Col */}
            {/* <div className='col-md-6 col-lg-6 col-xl-6 mb-md-5 mb-xl-3 mt-0'>
                <AccountPayable />
              </div> */}
            {/* end::Col */}

            {/* begin::Col */}
            {/* <div className='col-md-6 col-lg-6 col-xl-6 mb-md-5 mb-xl-3 mt-0'>
                <AccountMovable />
              </div> */}
            {/* end::Col */}

            {/* <div className='col-4 mb-md-5 mb-xl-3'> */}
            {/* begin::Col */}
            {/* <InventoryAsset
                  className='card-xl mb-xl-8 shadow-wit-2'
                  // className='card-xl-stretch mb-xl-8'
                  svgIcon='chart-simple'
                  color='white'
                  iconColor='primary'
                  title='Rp 500.000.000'
                  description='Inventory Asset'
                />
                <InventoryCategory className='mb-5 mb-xl-10 shadow-wit-2' /> */}
            {/*   end::Col */}
            {/* </div> */}
            {/* <div className='col-8 mb-md-5 mb-xl-3'>
                <InventoryRestock className='card-xxl-stretch mb-3 mb-xl-8 shadow-wit-2' />
              </div> */}
          </div>
          {/* end::Row */}
        </>
      )}
    </>
  )
}
const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <RatingQueryRequestProvider>
        <QueryRequestProvider>
          <TopQueryRequestProvider>
            <RatingQueryResponseProvider>
              <OutletQueryResponseProvider>
                <CityQueryResponseProvider>
                  <AreaQueryResponseProvider>
                    <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.HOME'})}</PageTitle>
                    <DashboardPage />
                  </AreaQueryResponseProvider>
                </CityQueryResponseProvider>
              </OutletQueryResponseProvider>
            </RatingQueryResponseProvider>
          </TopQueryRequestProvider>
        </QueryRequestProvider>
      </RatingQueryRequestProvider>
    </>
  )
}

export {DashboardWrapper}
